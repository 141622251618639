import React, { useEffect } from 'react'
import tw, { styled } from 'twin.macro'
import dayjs from 'dayjs'
import { map } from 'lodash'
import { StaticImage } from 'gatsby-plugin-image'
import { useAnimation, motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { Container } from 'semantic-ui-react'
import { buttonVariants, revealVariants } from '../../utils/motion'
import { Heading2, Description } from './Base'
import CountdownClock from '../Countdown/CountdownClock'
import { SHIPPING_INFO_F2024 } from '../../utils/constants'
const { FREE_SHIP_VALID_NORMAL_F2024 } = SHIPPING_INFO_F2024

const shipFeature = `Đội ship hỏa tốc từ 1h, ship COD không cọc. Freeship từ ${
  FREE_SHIP_VALID_NORMAL_F2024 / 1000
}k. Nhận bánh muộn nhất sau 4h từ khi đặt.`
const fruitFeature =
  'Sử dụng nhiều hoa quả tươi nhất VBB: dâu, nho, bơ, xoài, cherry, kiwi, sầu riêng, chanh leo, việt quất'
const varietyFeature =
  'Đa dạng size bánh chỉ từ 150k, 99+ mẫu bánh sinh nhật, sự kiện'
const vatFeature = 'Chứng nhận ISO 22000:2018, đảm bảo VSATTP. Có hóa đơn VAT'
const REASONS = [
  {
    image: (
      <StaticImage
        src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/feature-section/ship.png"
        alt={shipFeature}
      />
    ),
    title: shipFeature,
    link: 'ship',
    scale: true,
  },
  {
    image: (
      <StaticImage
        src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/feature-section/fruits.png"
        alt={fruitFeature}
      />
    ),
    title: fruitFeature,
    scale: true,
    link: 'scake-fruit',
  },
  {
    image: (
      <StaticImage
        src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/feature-section/cake.png"
        alt={varietyFeature}
      />
    ),
    title: varietyFeature,
    link: 'collection',
  },
  {
    image: (
      <StaticImage
        // src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/feature-section/feVat.png"
        src="https://s3.kstorage.vn/savor-web/src/assets/images/logos/logo-iso-22000.png"
        alt={vatFeature}
        width={80}
      />
    ),
    title: vatFeature,
    link: 'mousse',
  },
]

const SectionFeatureContainer = styled.div`
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  gap: 23px;
  flex-direction: column;
  @media only screen and (min-width: 1024px) {
    flex-direction: row;
    align-items: center;
  }
  .feature__ques {
    text-align: center;
    @media only screen and (min-width: 1024px) {
      max-width: 340px;
    }
  }
  .feature__reason-list {
    flex: 1;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    @media screen and (min-width: 640px) {
      gap: 20px;
    }
  }
  .feature__reason {
    border-radius: 15px;
    display: flex;
    flex: 1 0;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px 5px;
    box-shadow: 14px 32px 75px -35px rgba(9, 101, 58, 0.14);
    @media screen and (min-width: 640px) {
      padding: 25px 20px;
    }
  }
`

const MobileHorizontalLine = tw.div`md:hidden m-auto w-1/3 h-1.5 rounded bg-savor-cake-primary-green-avocado-0`

const SectionFeature = () => {
  const controls = useAnimation()
  const [ref, inView] = useInView()
  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])
  return (
    <motion.div
      ref={ref}
      variants={revealVariants}
      initial="hidden"
      animate={controls}
      tw="pt-10 lg:pt-6 lg:pb-8"
      id="features"
    >
      <Container>
        <SectionFeatureContainer>
          <div className="feature__ques">
            <Heading2 tw="lg:text-left text-savor-cake-primary-green-avocado-0">
              Tại sao bạn nên lựa chọn bánh Savor Cake
            </Heading2>
            <MobileHorizontalLine />
            <Description
              tw="lg:text-left"
              marginBigScreenNotAuto
              style={{ color: '#2F2105' }}
            >
              Hãy cùng tìm hiểu những đặc điểm nổi bật của Savor Cake nhé!
            </Description>
          </div>
          <div className="feature__reason-list">
            {map(REASONS, (item, index) => (
              <motion.a
                key={index}
                className="feature__reason"
                variants={buttonVariants}
                whileHover="hover"
                tw="border border-solid border-savor-cake-primary-green-avocado-0 gap-2 cursor-pointer"
                css={
                  index === 1
                    ? tw`flex-[1.8 1.8 0%]! lg:flex-[1.8 1.8 0%]! self-stretch`
                    : index === 0
                    ? tw`flex-[1.5 1.5 0%]! lg:flex-[1.5 1.5 0%]! self-stretch`
                    : index === 2
                    ? tw`flex-[1.2 1.2 0%]! lg:flex-[1.2 1.2 0%]! self-stretch`
                    : index === 3
                    ? tw`flex-[0.5 0.5 0%]! lg:flex-[0.5 0.5 0%]! self-stretch`
                    : ''
                }
                href={`#${item.link}`}
              >
                <div tw="w-4/5 flex flex-col justify-center items-center">
                  {item.image}
                </div>
                <p
                  tw="font-savor-cake-primary-lexend font-medium sm:font-semibold text-savor-cakes-grey-200
                  not-italic text-center text-xs sm:text-sm md:text-base xl:text-lg md:leading-relaxed lg:leading-normal"
                >
                  {item.title}
                </p>
              </motion.a>
            ))}
          </div>
        </SectionFeatureContainer>
        {dayjs().isBefore(dayjs('2024-01-16')) ? (
          <CountdownClock targetDatetime={new Date('2024-01-15 20:30:00')} />
        ) : null}
      </Container>
    </motion.div>
  )
}

export default SectionFeature
