import * as React from 'react'
import dayjs from 'dayjs'
import tw from 'twin.macro'
import { StaticImage } from 'gatsby-plugin-image'
import { Icon } from 'semantic-ui-react'
import { useStaticQuery, graphql } from 'gatsby'

import { Line } from './Base'
import { Container, Header, Description } from '../Base/Feedback/Styles'
import * as sliderStyles from './Styles/FeedbackSlider.module.scss'
import FeedbackSlider from '../Base/Feedback/Slider'
import { BubbleTopRight } from '../Locations'

const AbsoluteStyle = {
  position: 'absolute',
  minWidth: '100px',
  minHeight: '100px',
  height: 'auto',
  overflow: 'visible',
  aspectRatio: 'auto',
}
const IconTop = () => {
  return (
    <StaticImage
      src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/fb-bg-top.png"
      alt="bubble"
      layout="fullWidth"
      style={{
        ...AbsoluteStyle,
        width: '25vw',
        left: 0,
        top: '-9vh',
      }}
    />
  )
}
export const IconBot = ({ customStyle }) => {
  return (
    <StaticImage
      src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/fb-bg-bot.png"
      alt="bubble"
      layout="fullWidth"
      style={{
        ...AbsoluteStyle,
        width: '25vw',
        right: 0,
        bottom: 0,
        ...customStyle,
      }}
    />
  )
}

function CustomerFeedback(props, ref) {
  const data = useStaticQuery(graphql`
    {
      allS3Object(
        filter: { Key: { regex: "/fb-bsn-2024T9/" } }
        sort: { fields: [Key], order: ASC }
      ) {
        totalCount
        edges {
          node {
            Key
            localFile {
              name
              childrenImageSharp {
                gatsbyImageData(width: 800, height: 800)
              }
            }
          }
        }
      }
    }
  `)

  return (
    <div
      id="scake-savor-cake-main-feedback"
      ref={ref}
      tw="bg-savor-cake-primary-beige-200 relative"
    >
      <IconTop />
      <BubbleTopRight
        customStyle={{ ...AbsoluteStyle, top: null, bottom: '-2rem' }}
      />
      <div
        tw="relative"
        style={{
          // backgroundImage: isMediumScreen ? `url(${mobileBg})` : ``,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <Container css={tw`bg-transparent pb-0`}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Header tw="z-40 text-savor-cake-primary-green-avocado-100">
              Feedback tháng {dayjs().format('MM/YYYY')}{' '}
              <Icon size="tiny" name="heart" />
            </Header>
            <Line />
          </div>
          <Description tw="mt-4 text-savor-cakes-grey-500">
            Savor Cake đã nhận được nhiều phản hồi tích cực từ phía khách hàng
            khi sử dụng sản phẩm bánh sinh nhật của chúng mình ... Cùng xem thử
            các mẫu bánh được khách yêu tin tưởng ủng hộ dưới đây nhé!
          </Description>
          <FeedbackSlider
            data={data}
            styles={sliderStyles}
            nodeName="allS3Object"
          ></FeedbackSlider>
        </Container>
      </div>
    </div>
  )
}
const forwarded = React.forwardRef(CustomerFeedback)
export default forwarded
