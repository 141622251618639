import React, { useRef, useEffect, useState } from 'react'
import 'twin.macro'
// import dayjs from 'dayjs'
import _ from 'lodash'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import { useLocation } from '@gatsbyjs/reach-router'

import { schemaMarkupCakeItemList } from '../utils/seoSchemaMarkup'
import { siteUrl } from '../utils/constants'
import { fetchSale, getNodeNameByData } from '../utils/utils'
import CollectionMousse from '../components/BanhSinhNhatPage/CollectionMousse'
import SectionHero from '../components/BanhSinhNhatPage/SectionHero'
import SectionFeature from '../components/BanhSinhNhatPage/SectionFeature'
import { SectionContainer } from '../components/BanhSinhNhatPage/Base'
import ProductGroupsSection from '../components/BanhSinhNhatPage/ProductGroupsSection'
import Footer from '../components/Base/Footer'
import SectionKorea from '../components/BanhSinhNhatPage/SectionKorea'
import CustomerFeedback from '../components/BanhSinhNhatPage/CustomerFeedback'
import SocialEventsSection from '../components/BanhSinhNhatPage/SocialEventsSection'
import ModalViewProduct from '../components/Base/ProductGroup/ModalViewProduct'
import AddonsModal from '../components/Base/Modals/AddonsModal.jsx'
import SectionCombo from '../components/BanhSinhNhatPage/SectionCombo'
import ShipperSection from '../components/BanhSinhNhatPage/ShipperSection'
import CakePolicySection from '../components/BanhSinhNhatPage/CakePolicySection'
import AddonSection from '../components/BanhSinhNhatPage/AddonSection'
import CakeLocations from '../components/Locations'
import SEOComponent from '../components/SEOComponent'
// import ImagePopup from '../components/ImagePopup'

import { ImageDataContext } from '../states/imageDataContext'
import useInViewTime from '../hooks/useInViewTime'
import { cakeLocations, outletConfigs } from '../utils/locations'
import ButtonRightButtons from '../components/Shared/BottomRightButtons.jsx'
import ButtonLeftButtons from '../components/Shared/BottomLeftButtons.jsx'
import TailwindLayout from '../components/Layouts/TailwindLayout.js'
import { saleMapAtom } from '../states/saleData.js'
import { useAtom } from 'jotai'

const metaDescription =
  'Bánh sinh nhật chất lượng với nguyên liệu, hoa quả tươi ngon mỗi ngày tại Hà Nội. Giá phải chăng chỉ từ 120k, đa dạng size, mẫu mã và hương vị như Mousse, Sữa chua hoa quả, Bông lan trứng muối, Tirmisu, Bánh kem sự kiện, Bánh kem trẻ em, Bánh kem Hàn Quốc, ...'
const schemaMarkup = {
  '@context': 'http://schema.org',
  '@graph': [
    {
      '@type': 'ItemList',
      itemListElement: schemaMarkupCakeItemList,
    },
    {
      '@type': 'BreadcrumbList',
      itemListElement: [
        {
          '@type': 'ListItem',
          position: 1,
          item: {
            '@id': 'https://www.savor.vn/',
            name: 'Trang chủ',
          },
        },
        {
          '@type': 'ListItem',
          position: 2,
          item: {
            '@id': 'https://www.savor.vn/#collection',
            name: 'BST Bánh sinh nhật',
          },
        },
      ],
    },
  ],
}
export default function BanhSinhNhatPage() {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)

  // Dữ liệu sale
  const [, setSaleMap] = useAtom(saleMapAtom)
  useEffect(() => {
    async function fetchSaleData() {
      const saleData = await fetchSale()
      setSaleMap(saleData)
    }
    fetchSaleData()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // auto add cart
  const autoSku = queryParams.get('banh')
  const psid = queryParams.get('psid')
  useEffect(() => {
    let link = '/checkout/'
    const params = []
    if (autoSku) {
      params.push(`banh=${autoSku}`)
    }
    if (psid) {
      // chỉ để hiện lúc redirect checkout, còn lưu vào cookie theo seocomponent all pages rồi
      params.push(`psid=${psid}`)
    }
    if (autoSku) {
      link += `?${params.join('&')}`
      navigate(link)
    }
  }, [autoSku, psid])

  // React.useEffect(() => {
  //   setTimeout(() => {
  //     if (
  //       typeof window !== 'undefined' &&
  //       typeof window.FB !== 'undefined' &&
  //       typeof window.FB.CustomerChat !== 'undefined'
  //     ) {
  //       window.FB.CustomerChat.hide()
  //     }
  //   }, 5000)
  // }, [])
  const featureRef = useRef(null)
  const productsRef = useRef(null)
  const koreaRef = useRef(null)
  const feedbackRef = useRef(null)
  const socialEventRef = useRef(null)
  const comboRef = useRef(null)
  const footerRef = useRef(null)
  const policyRef = useRef(null)
  const locationRef = useRef(null)
  const refMap = {
    featureRef,
    productsRef,
    koreaRef,
    feedbackRef,
    socialEventRef,
    comboRef,
    footerRef,
    policyRef,
    locationRef,
  }

  // Dữ liệu ảnh xem thêm
  const imagesData = useStaticQuery(graphql`
    {
      allS3Object(
        filter: { Key: { regex: "/images/products/" } }
        sort: { fields: [Key], order: ASC }
      ) {
        totalCount
        edges {
          node {
            Key
            localFile {
              name
              childrenImageSharp {
                gatsbyImageData(width: 600)
              }
            }
          }
        }
      }
    }
  `)
  // const imagesData = []
  const nodeName = getNodeNameByData(imagesData)

  useEffect(() => {
    const trackElems = document.querySelectorAll('.slick-track')
    _.forEach(trackElems, (trackElem) => {
      const childNum = trackElem?.children.length
      // Số thẻ hiện full
      if (childNum <= 4) {
        trackElem?.classList.add('remain-track')
      } else {
        trackElem?.classList.remove('remain-track')
      }
    })
  }, [])

  const idToScroll = queryParams.get('viewId')
  useEffect(() => {
    if (idToScroll) {
      const timer = setTimeout(() => {
        const element = document.getElementById(idToScroll)
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
      }, 1500)
      return () => clearTimeout(timer)
    }
  }, [idToScroll])

  useInViewTime({
    targetSections:
      typeof document !== 'undefined' &&
      document.querySelectorAll('[id^="scake-"]'),
  })

  // const [fixedNote, setFixedNote] = useState(false)
  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (window.scrollY > 100) {
  //       setFixedNote(true)
  //     } else {
  //       setFixedNote(false)
  //     }
  //   }

  //   window.addEventListener('scroll', handleScroll)
  //   return () => window.removeEventListener('scroll', handleScroll)
  // }, [])

  return (
    <ImageDataContext.Provider value={imagesData}>
      <TailwindLayout
        className="landing-page-banh-sinh-nhat"
        style={{ overflow: 'hidden', position: 'relative' }}
      >
        <ButtonLeftButtons />
        <ButtonRightButtons />
        <ModalViewProduct nodeName={nodeName} />
        <AddonsModal />
        {/* {!dayjs().isBefore(dayjs('2024-09-07').endOf('day')) ? null : (
          <div
            css={fixedNote ? tw`fixed` : tw`sticky`}
            tw=" z-[99999] top-0 w-full h-auto p-2 bg-savor-cake-secondary-green-matcha-200 text-center flex justify-center items-center text-sm lg:text-base"
          >
            Do ảnh hưởng của cơn bão Yagi, Savor Cake xin tạm dừng hoạt động
            ngày 07/09/2024. Chúc mọi người tránh bão an toàn!
          </div>
        )} */}
        <SectionHero refMap={refMap} />
        {/* <CollectionTetCakeCandy /> */}
        <ProductGroupsSection type="BTT" /> {/* sửa cùng hero wave */}
        <SectionContainer
          ref={featureRef}
          className="section-features"
          id="scake-savor-cake-main-feature"
        >
          <SectionFeature />
        </SectionContainer>
        <ShipperSection />
        <CollectionMousse />
        <ProductGroupsSection ref={productsRef} />
        <AddonSection />
        <SectionKorea ref={koreaRef} />
        <SectionCombo ref={comboRef} />
        <CakePolicySection ref={policyRef} />
        <CakeLocations
          ref={locationRef}
          locationData={cakeLocations}
          outletConfigs={outletConfigs}
          theme={'green'}
        />
        <CustomerFeedback ref={feedbackRef} />
        <SocialEventsSection />
        <Footer ref={footerRef} id="scake-savor-cake-main-footer" />
        <SEOComponent
          title={'Bánh sinh nhật - Savor Cake'}
          description={metaDescription}
          keywords={
            'bánh sinh nhật, bánh kem, birthday cake, mousse, tiramisu, bông lan trứng muối'
          }
          altUrl={`${siteUrl}`}
          schemaMarkup={schemaMarkup}
        />
      </TailwindLayout>
    </ImageDataContext.Provider>
  )
}
