import React, { forwardRef } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { SectionContainer } from './Base'
import SectionProducts from './SectionProducts'
import { valentineAvailable } from '../../utils/eventDays'
// import useMediaQuery from '../../hooks/useMediaQuery'

const AbsoluteStyle = {
  position: 'absolute',
  minWidth: '100px',
  minHeight: '100px',
  height: 'auto',
  zIndex: 0,
  overflow: 'hidden',
  aspectRatio: 'auto',
}
const BlueBerryTop = () => {
  return (
    <StaticImage
      src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/product-related-sections/bg-details/blueberry-top-left.png"
      alt="Blueberry"
      placeholder="blurred"
      layout="fullWidth"
      style={{ ...AbsoluteStyle, width: '15vw', left: 0, top: 0 }}
    />
  )
}

const BubbleTopRight = () => {
  return (
    <StaticImage
      src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/product-related-sections/bg-details/bubble-top-right.png"
      alt="bubble"
      placeholder="blurred"
      layout="fullWidth"
      style={{ ...AbsoluteStyle, width: '25vw', right: 0, top: '50rem' }}
    />
  )
}

const BubbleMidLeft = () => {
  return (
    <StaticImage
      src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/product-related-sections/bg-details/bubble-mid-left.png"
      alt="bubble"
      placeholder="blurred"
      layout="fullWidth"
      style={{
        ...AbsoluteStyle,
        width: '25vw',
        left: 0,
        top: '120rem',
      }}
    />
  )
}

const BlueberryMid = () => {
  return (
    <StaticImage
      src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/product-related-sections/bg-details/blueberry-mid.png"
      alt="blueberry"
      placeholder="blurred"
      style={{
        ...AbsoluteStyle,
        right: '5vw',
        top: '150rem',
      }}
    />
  )
}
export const BubbleMidRight = ({ customStyle }) => {
  return (
    <StaticImage
      src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/product-related-sections/bg-details/bubble-mid-right.png"
      alt="bubble"
      placeholder="blurred"
      style={{
        ...AbsoluteStyle,
        right: 0,
        bottom: '200rem',
        ...customStyle,
      }}
    />
  )
}
const BubbleBotLeft = () => {
  return (
    <StaticImage
      src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/product-related-sections/bg-details/bubble-bot-left.png"
      alt="bubble"
      placeholder="blurred"
      style={{
        ...AbsoluteStyle,
        left: 0,
        bottom: '100rem',
      }}
    />
  )
}
const AvocadoBotRight = () => {
  return (
    <StaticImage
      src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/product-related-sections/bg-details/avocado-bot-right.png"
      alt="avocado"
      placeholder="blurred"
      style={{
        ...AbsoluteStyle,
        right: 0,
        bottom: '15rem',
      }}
    />
  )
}

export default forwardRef((props, ref) => {
  // const isMediumScreen = useMediaQuery('(min-width: 768px)')
  return (
    <SectionContainer
      ref={ref}
      background="#FEFBF0"
      padding={props.type === 'BKT' ? '0' : ''}
      paddingTop={props.type === 'BKT' ? '10px' : ''}
    >
      {!props.type ? (
        <>
          <BlueBerryTop />
          {/* {!isMediumScreen && (
            <StaticImage
              src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/product-related-sections/product-top-wave-mobile.png"
              alt="wave"
              layout="fullWidth"
              placeholder="blurred"
            />
          )}
          {isMediumScreen && (
            <StaticImage
              src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/product-related-sections/product-top-wave.png"
              alt="wave"
              layout="fullWidth"
              placeholder="blurred"
            />
          )} */}
          {/* bg sub images */}
          <BubbleTopRight />
          <BubbleMidLeft />
          <BlueberryMid />
          <BubbleMidRight />
          <BubbleBotLeft />
          <AvocadoBotRight />
          {/* main */}
          <SectionProducts />
        </>
      ) : null}
      {props.type === 'BKT' ? (
        <>
          <SectionProducts type="BKT" />
          {valentineAvailable ? <SectionProducts type="VLT" /> : null}
          <StaticImage
            src="../../assets/images/landing-page-bsn/hero-section/rebrand/d-wave.png"
            alt="wave image"
            layout="fullWidth"
            loading="eager"
            placeholder="blurred"
          />
        </>
      ) : null}
      {props.type === 'BTT' ? (
        <>
          {/* <StaticImage
            src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/product-related-sections/combo-bot-wave-thin.png"
            alt="wave"
            layout="fullWidth"
            placeholder="blurred"
          /> */}
          <SectionProducts type="BTT" />
          <StaticImage
            src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/product-related-sections/d-wave-white-thin.png"
            alt="wave image"
            layout="fullWidth"
            loading="eager"
            placeholder="blurred"
          />
        </>
      ) : null}
    </SectionContainer>
  )
})
