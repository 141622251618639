import React from 'react'
import 'twin.macro'
import { Heading1, Line, ProductSection, Description } from './Base'
import ProductGroups from './ProductGroups'
import {
  products,
  tetCollection,
  socolaProducts,
  cakeTetCollection,
} from '../../utils/products'
import { socolaLocations, openTetText } from '../../utils/locations'
import { Container } from 'semantic-ui-react'
import { groupBy } from 'lodash'
import { mooncakes } from '../../utils/mooncakes'

const socolaLocationsGroup = groupBy(socolaLocations, 'closeTetText')
const closeTetTextKeys = Object.keys(socolaLocationsGroup)

const productTypesMap = {
  BSN: {
    id: 'collection',
    heading: (
      <>
        Bộ sưu tập bánh kem, bánh sinh nhật
        <br />
        <span> Savor Cake</span>
      </>
    ),
    desc: 'Mời bạn xem ngay 99+ mẫu bánh kem, bánh sinh nhật tươi ngon, đa dạng, giá chỉ từ 120k',
    products,
  },
  BKT: {
    id: 'tet-collection',
    heading: (
      <>
        Bộ sưu tập bánh kẹo Tết 2024
        <br />
        <span> Savor Cake</span>
      </>
    ),
    desc: (
      <>
        Nhân dịp Tết đến xuân về, Savor Cake phục vụ thêm khách yêu các món bánh
        kẹo Tết thơm ngon từ nguồn nguyên liệu chất lượng, phù hợp mua ăn gia
        đình hoặc làm quà biếu tặng. <br />
        Quý khách có nhu cầu mua sỉ bánh kẹo Tết, vui lòng liên hệ{' '}
        <a href="tel:0812768688">0812768688</a> để được tư vấn mức chiết khấu
        phù hợp nhất
        <br />
        <span>
          (*) Savor có nhận ship tỉnh, trừ 2 mã hộp quà Tết có lọ thủy tinh dễ
          vỡ ạ
        </span>
      </>
    ),
    products: [...tetCollection, ...cakeTetCollection],
  },
  VLT: {
    id: 'socola-valentine',
    heading: <>Valentine 2024</>,
    desc: (
      <>
        <div tw="mb-4">
          Savor Cake ra mắt 2 set socola truffle, giúp khách yêu thay lời muốn
          nói, gửi ngọt ngào tới người thương yêu
          <br />
          (*) Sẵn hàng đến hết 15/2/2024 (trừ ngày nghỉ lễ của cơ sở).{' '}
          <strong>
            Khách đặt ship về hoặc đặt trước rồi qua lấy trực tiếp tại 1 trong 5
          </strong>{' '}
          địa chỉ sau:
          <br />
          ------------
        </div>
        <div tw="mt-3 w-2/3! flex! flex-col justify-start">
          {closeTetTextKeys.map((key, index) => {
            const locations = socolaLocationsGroup[key]
            return (
              <div index={index} tw="">
                <strong>{key}:</strong>
                {locations.map((location) => (
                  <div key={location.code} tw="text-left! m-0 mb-2">
                    {location.address} -{' '}
                    <a
                      href={`tel:${location.phone}`}
                      tw="text-black no-underline"
                    >
                      {location.phone}
                    </a>
                  </div>
                ))}
              </div>
            )
          })}
          ------------
          <br />
          Cả 5 cơ sở: <strong>{openTetText}</strong>
        </div>
      </>
    ),
    products: socolaProducts,
  },
  BTT: {
    id: 'mooncake',
    heading: (
      <>
        Bánh trung thu 2024
        <br />
      </>
    ),
    desc: (
      <>
        <div>
          Bánh Trung Thu handmade của Savor Cake với hương vị thơm ngon, giảm
          ngọt. Mùa Trăng năm nay Savor Cake có 4 vị bánh nướng vỏ màu hiện đại,
          8 vị bánh nướng truyền thống và 2 vị bánh dẻo. Ngoài ra, các set hộp
          2-4-6 bánh cũng rất phù hợp để biếu tặng, với chiết khấu doanh nghiệp{' '}
          <strong>lên tới 40%</strong>.
        </div>
      </>
    ),
    products: mooncakes,
  },
}

const SectionProducts = ({ type = 'BSN' }) => {
  const {
    id,
    heading,
    desc,
    products: typedProducts,
  } = productTypesMap[type] || {}
  return (
    <Container id={id}>
      <ProductSection vertical textAlign="center">
        <Heading1>{heading}</Heading1>
        <Line center={true}></Line>
        <Description>{desc}</Description>
        <ProductGroups products={typedProducts} type={type} />
      </ProductSection>
    </Container>
  )
}

export default SectionProducts
